import 'core-js/fn/object/assign'
import domready from 'domready'
import xhr from 'xhr'

function debugWindow (json) {
  let data = {
    dataString: json,
    modus: 'setDebug'
  }
  xhr({
    body: JSON.stringify(data),
    uri: 'ajax.php',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, function (err, resp, body) {
    console.log(err)
    let winURL = 'api/debug.php'
    let winName = 'win1'
    let winSize = 'width=660,height=620,scrollbars=yes'
    window.open(winURL, winName, winSize)
  })
}

export default function categoryInit () {
  domready(() => {
    let debug = document.getElementById('debug-data')
    if (debug !== null) {
      let json = debug.innerHTML
      debugWindow(json)
    }
  })
}
