import xhr from 'xhr'
var path = 'ajax.php'

export default function contactFiles () {
  var uploadButton = document.getElementById('kdz-arbeit-upload')
  if (uploadButton !== null) {
    var url = 'ajaxfile.php'

    document.getElementById('kdz-arbeit-upload').addEventListener('change', function (e) {
      var progressBar = document.getElementById('meter-span')
      progressBar.style.width = '0'
      var formData = new FormData() // eslint-disable-line
      for (var i = this.files.length - 1; i >= 0; i--) {
        formData.append('file[' + i + ']', this.files[ i ])
      }

      var xhr = new XMLHttpRequest() // eslint-disable-line

      xhr.addEventListener('progress', function (e) {
        var done = e.position || e.loaded
        var total = e.totalSize || e.total
        console.log('xhr progress: ' + (Math.floor(done / total * 1000) / 10) + '%')
      }, false)
      if (xhr.upload) {
        xhr.upload.onprogress = function (e) {
          var done = e.position || e.loaded
          var total = e.totalSize || e.total
          var progress = (Math.floor(done / total * 1000) / 10)

          progressBar.style.width = progress + '%'
        }
      }
      xhr.onreadystatechange = function (e) {
        if (this.readyState === 4) {
          var response = xhr.responseText
          var responseArr = JSON.parse(response)
          var textDiv = document.getElementById('file-arbeit')
          if (responseArr[ 'error' ] === 1) {
            textDiv.innerHTML = responseArr[ 'msg' ]
          } else {
            textDiv.innerHTML = responseArr[ 'html' ]
          }
          var delteFile = document.querySelectorAll('a.delete-file')
          if (delteFile !== null) {
            for (var i = delteFile.length - 1; i >= 0; i--) {
              delteFile[ i ].addEventListener('click', function (event) {
                deleteFile(this)
                event.preventDefault()
                return false
              })
            }
          }
        }
      }
      xhr.open('post', url, true)
      // xhr.setRequestHeader('Content-Type', 'multipart/form-data')
      xhr.send(formData)
    }, false)
  }
  var deleteFile = function (element) {
    var aktFile = element.getAttribute('data-file')
    xhr({
      useXDR: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest'
      },
      body: 'file_selected=' + aktFile + '&modus=deleteFile',
      url: path
    }, function (err, resp) {
      // check resp.statusCode
      if (resp.statusCode === 200) {
        console.log(resp)
        var response = JSON.parse(resp.body)
        var textDiv = document.getElementById('file-arbeit')
        textDiv.innerHTML = response.html

        var delteFile = document.querySelectorAll('a.delete-file')
        if (delteFile !== null) {
          for (var i = delteFile.length - 1; i >= 0; i--) {
            delteFile[ i ].addEventListener('click', function (event) {
              deleteFile(this)
              event.preventDefault()
              return false
            })
          }
        }
      }
      if (err) {
        console.log(err)
      }
    })
  }
  var delteFile = document.querySelectorAll('a.delete-file')
  if (delteFile !== null) {
    for (var i = delteFile.length - 1; i >= 0; i--) {
      delteFile[ i ].addEventListener('click', function (event) {
        deleteFile(this)
        event.preventDefault()
        return false
      })
    }
  }
}
