import 'core-js/fn/object/assign'
import domready from 'domready'
import selectAll from './select-all'

function toggleBoxen (all) {
  let menu = selectAll('.kachlen-menu-item.active', window.body)
  let elements = selectAll('.box-leistung', window.body)
  let category = {}
  menu.map(el => {
    if (el.getAttribute('data-typ') !== 'all') {
      category[el.getAttribute('data-typ')] = el.getAttribute('data-typ')
    }
  })
  if (Object.keys(category).length > 0 && all === false) {
    elements.map(el => {
      if (category[el.getAttribute('data-type')]) {
        if (el.classList.contains('hidden-box')) {
          el.classList.remove('hidden-box')
        }
      } else {
        if (!el.classList.contains('hidden-box')) {
          el.classList.add('hidden-box')
        }
      }
    })
    if (document.getElementById('kachlen-menu-item-all').classList.contains('active')) {
      document.getElementById('kachlen-menu-item-all').classList.remove('active')
    }
  } else {
    elements.map(el => {
      if (el.classList.contains('hidden-box')) {
        el.classList.remove('hidden-box')
      }
    })
    menu.map(el => {
      el.classList.remove('active')
    })
    if (!document.getElementById('kachlen-menu-item-all').classList.contains('active')) {
      document.getElementById('kachlen-menu-item-all').classList.add('active')
    }
  }
}

function toggleCategory () {
  let elem = this
  let all = false
  if (elem.classList.contains('active')) {
    elem.classList.remove('active')
  } else {
    elem.classList.add('active')
    if (elem.getAttribute('data-typ') === 'all') {
      all = true
    }
  }
  toggleBoxen(all)
}

export default function categoryInit () {
  domready(() => {
    var classname = document.getElementsByClassName('kachlen-menu-item')
    for (var i = 0; i < classname.length; i++) {
      classname[i].addEventListener('click', toggleCategory)
    }
  })
}
